import * as React from 'react'
import styled from 'styled-components'
import theme from '../../components/atoms/theme'
import type { ContentBlocksType } from '../../templates/page'
import RichTextRenderer from '../../templates/rich-text-renderer'
import BusinessesBlock from '../organisms/businesses-block'
import ContentSetBlock from '../organisms/content-set-block'
import CtaBlock from '../organisms/cta-block'
import EventsBlock from '../organisms/events-block'
import { FlexibleTextAndImageBlock } from '../organisms/flexible-text-and-image-block'
import InfoBlock from '../organisms/info-block'
import PageHeroSlider from '../organisms/page-hero-slider'
import { RichTextOnLeftImageOnRightBlock } from '../organisms/rich-text-on-left-image-on-right'
import StoriesBlock from '../organisms/stories-block'
import Button, { type ButtonVisualStyle, type ButtonVisualType } from './button'

interface ContentBlockProps {
  blocks?: ContentBlocksType
}

export const ContentBlockWrapper = styled.div`
  margin: 7.5rem 0 0 0;

  p {
    font-size: 2rem;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 4.4rem;
      /* margin-bottom: 5.5rem; */

      &:after {
        content: '';
        height: 1.6rem;
        width: 1.6rem;
        background: ${theme.colours.quaternary};
        display: block;
        position: absolute;
        transform: rotate(45deg);
        top: 0.8rem;
        left: 0;
      }
    }
  }
`

export const ContentBlockTitle = styled.h2`
  font-size: 4rem;
  color: ${theme.colours.secondary};
  font-weight: 900;
  font-family: ${theme.fonts.primaryBlack};
  line-height: 140%;
  margin-bottom: 1.6rem;
`

export const ContentBlockDescription = styled.div`
  font-size: 2.4rem;
  line-height: 140%;

  p {
    font-size: 2.4rem;
    line-height: 140%;
  }
`

export const HeadingWrapper = styled.div`
  text-align: center;
  margin-bottom: 6rem;
`

const ContentBlocks = (props: ContentBlockProps): JSX.Element => (
  <div>
    {props?.blocks?.map((block) => {
      if (block?.__typename === 'ContentfulSliderBlock') {
        const sliderBlock = block
        if (sliderBlock?.slides && sliderBlock?.slides?.length > 0)
          return (
            <PageHeroSlider slides={sliderBlock.slides} key={sliderBlock.id} />
          )
      }

      if (block?.__typename === 'ContentfulBusinessesBlock') {
        return <BusinessesBlock block={block} key={block.id} />
      }

      if (block?.__typename === 'ContentfulInfoBlock') {
        const infoBlock = block
        return <InfoBlock block={infoBlock} key={infoBlock.id} />
      }

      if (block?.__typename === 'ContentfulContentSetBlock') {
        const contentSetBlock = block
        return (
          <ContentSetBlock block={contentSetBlock} key={contentSetBlock.id} />
        )
      }

      if (block?.__typename === 'ContentfulStoriesBlock') {
        const storiesBlock = block
        return <StoriesBlock block={storiesBlock} key={storiesBlock.id} />
      }

      if (block?.__typename === 'ContentfulEventsBlock') {
        const eventsBlock = block
        return <EventsBlock block={eventsBlock} key={eventsBlock.id} />
      }

      if (block?.__typename === 'ContentfulCtaBlock') {
        const ctaBlock = block
        return <CtaBlock block={ctaBlock} key={ctaBlock.id} />
      }

      if (block?.__typename === 'ContentfulFlexibleTextAndImageBlock') {
        return <FlexibleTextAndImageBlock key={block.id} block={block} />
      }

      if (block?.__typename === 'ContentfulButton') {
        return (
          <Button
            href={block.url || undefined}
            key={block.id}
            visualType={(block.type as ButtonVisualType) || 'Solid'}
            visualStyle={(block.style as ButtonVisualStyle) || 'Primary'}
          >
            {block.label}
          </Button>
        )
      }

      if (block?.__typename === 'ContentfulRichTextBlock') {
        return (
          <RichTextRenderer richTextJson={block?.body?.json} key={block.id} />
        )
      }

      if (block?.__typename === 'ContentfulRichTextOnLeftImageOnRightBlock') {
        return <RichTextOnLeftImageOnRightBlock key={block.id} block={block} />
      }

      if (block && '__typename' in block) {
        console.warn(`Unhandled block type: ${block?.__typename}`)
      } else {
        console.warn('Unknown block type')
      }

      return null
    })}
  </div>
)

export default ContentBlocks
