import { graphql } from 'gatsby'
import { get } from 'lodash'
import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import ContentBlocks from '../components/molecules/content-blocks'
import type { ContentfulPage } from '../generated/graphql-types'

export type ContentBlocksType = ContentfulPage['contentBlocks']

interface Props {
  data: {
    contentfulPage: ContentfulPage
  }
}

const Page = (props: Props) => {
  const { title, contentBlocks } = props.data.contentfulPage
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const currentPath = get(props, 'location.pathname')

  let seoTitle: string

  // If current url is /, set the title to the site title
  if (currentPath === '/') {
    seoTitle = siteTitle || ''
  }
  // Otherwise, set the title to the page title
  else {
    seoTitle = `${title} | ${siteTitle}`
  }

  return (
    <Layout currentPath={currentPath}>
      <Helmet title={seoTitle} />
      <Helmet>
        <meta
          name='google-site-verification'
          content='KxzHHxPTYUBNF8kTL0ZNNV8B1OikoDidcecqx3Upihk'
        />
      </Helmet>
      <ContentBlocks blocks={contentBlocks} />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ContentfulPageQuery($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulPage(slug: {eq: $slug}) {
      id
      title
      contentBlocks {
        ... on Node {
          ... on ContentfulSliderBlock {
            ...ContentfulSliderBlockFragment
          }
          ... on ContentfulInfoBlock {
            ...ContentfulInfoBlockFragment
          }
          ... on ContentfulContentSetBlock {
            ...ContentfulContentSetBlockFragment
          }
          ... on ContentfulStoriesBlock {
            ...ContentfulStoriesBlockFragment
          }
          ... on ContentfulEventsBlock {
            ...ContentfulEventsBlockFragment
          }
          ... on ContentfulCtaBlock {
            ...ContentfulCtaBlockFragment
          }
          ... on ContentfulFlexibleTextAndImageBlock {
            ...ContentfulFlexibleTextAndImageBlockFragment
          }
          ... on ContentfulButton {
            ...ButtonFragment
          }
          ... on ContentfulRichTextBlock {
            ...ContentfulRichTextBlockFragment
          }
          ... on ContentfulRichTextOnLeftImageOnRightBlock {
            ...ContentfulRichTextOnLeftImageOnRightBlockFragment
          }
          ... on ContentfulBusinessesBlock {
            ...ContentfulBusinessesBlockFragment
          }
        }
      }
    }
  }
`
