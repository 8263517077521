import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import * as React from 'react'
import styled from 'styled-components'
import type { ContentfulInfoBlock } from '../../generated/graphql-types'
import theme from '../atoms/theme'
import Button from '../molecules/button'
import {
  ContentBlockDescription,
  ContentBlockTitle,
  ContentBlockWrapper,
  HeadingWrapper,
} from '../molecules/content-blocks'

type InfoBlockProps = {
  block: ContentfulInfoBlock
}

const ContentWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 6rem;
  margin-bottom: 6rem;

  @media only screen and ${theme.breakpoints.fromMediumScreen} {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }
`

const Column = styled.div``

const InfoBlock = (props: InfoBlockProps): JSX.Element => {
  const { block } = props
  const { title, description, image, body, buttons } = block

  return (
    <ContentBlockWrapper>
      <HeadingWrapper>
        <ContentBlockTitle>{title}</ContentBlockTitle>
        {description?.json && (
          <ContentBlockDescription>
            {documentToReactComponents(description.json)}
          </ContentBlockDescription>
        )}
      </HeadingWrapper>
      <ContentWrapper>
        {image?.file?.url && (
          <Column>
            <img
              src={`https:${image.file.url}`}
              alt={image.title || ''}
              width={image.file?.details?.image?.width || undefined}
              height={image.file?.details?.image?.height || undefined}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Column>
        )}
        {(body?.json || (buttons && buttons.length > 0)) && (
          <Column>
            {body?.json && documentToReactComponents(body.json)}
            {buttons && buttons.length > 0 && (
              <div>
                {buttons.map((button, index) => {
                  if (!button) return null
                  return (
                    <Button
                      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                      key={index}
                      visualStyle={
                        button.style && button.style === 'Secondary'
                          ? 'Secondary'
                          : 'Primary'
                      }
                      visualType={
                        button.type && button.type === 'Outline'
                          ? 'Outline'
                          : 'Solid'
                      }
                      to={button.url || '#'}
                    >
                      {button.label}
                    </Button>
                  )
                })}
              </div>
            )}
          </Column>
        )}
      </ContentWrapper>
    </ContentBlockWrapper>
  )
}

export default InfoBlock
